import React, { useEffect, useRef } from 'react';
import { motion } from 'framer-motion';

const ParticleEffect = () => {
    const canvasRef = useRef(null);

    useEffect(() => {
        const canvas = canvasRef.current;
        const ctx = canvas.getContext('2d');
        let animationFrameId;

        canvas.width = 200;
        canvas.height = 80;

        const particles = [];

        for (let i = 0; i < 50; i++) {
            particles.push({
                x: Math.random() * canvas.width,
                y: Math.random() * canvas.height,
                radius: Math.random() * 2 + 1,
                dx: (Math.random() - 0.5) * 2,
                dy: (Math.random() - 0.5) * 2
            });
        }

        const animate = () => {
            ctx.clearRect(0, 0, canvas.width, canvas.height);

            particles.forEach(particle => {
                particle.x += particle.dx;
                particle.y += particle.dy;

                if (particle.x < 0 || particle.x > canvas.width) particle.dx *= -1;
                if (particle.y < 0 || particle.y > canvas.height) particle.dy *= -1;

                ctx.beginPath();
                ctx.arc(particle.x, particle.y, particle.radius, 0, Math.PI * 2);
                ctx.fillStyle = 'rgba(255, 255, 255, 0.5)';
                ctx.fill();
            });

            animationFrameId = requestAnimationFrame(animate);
        };

        animate();

        return () => {
            cancelAnimationFrame(animationFrameId);
        };
    }, []);

    return <canvas ref={canvasRef} className="absolute inset-0" />;
};

const MorphingShape = () => (
    <svg className="absolute inset-0 w-full h-full" viewBox="0 0 200 80">
        <motion.path
            fill="none"
            stroke="url(#gradient)"
            strokeWidth="3"
            d="M 0, 40 C 0, 20 20, 0 40, 0 S 80, 20 80, 40 60, 80 40, 80 0, 60 0, 40"
            animate={{
                d: [
                    "M 0, 40 C 0, 20 20, 0 40, 0 S 80, 20 80, 40 60, 80 40, 80 0, 60 0, 40",
                    "M 0, 40 C 10, 30 30, 10 50, 10 S 90, 30 90, 50 70, 90 50, 90 10, 70 0, 40",
                    "M 0, 40 C 20, 40 40, 20 60, 20 S 100, 40 100, 60 80, 100 60, 100 20, 80 0, 40",
                    "M 0, 40 C 0, 20 20, 0 40, 0 S 80, 20 80, 40 60, 80 40, 80 0, 60 0, 40",
                ]
            }}
            transition={{
                duration: 10,
                repeat: Infinity,
                ease: "easeInOut"
            }}
        />
        <defs>
            <linearGradient id="gradient" x1="0%" y1="0%" x2="100%" y2="0%">
                <stop offset="0%" stopColor="#3b82f6">
                    <animate attributeName="stop-color" values="#3b82f6; #8b5cf6; #ec4899; #3b82f6" dur="10s" repeatCount="indefinite" />
                </stop>
                <stop offset="100%" stopColor="#ec4899">
                    <animate attributeName="stop-color" values="#ec4899; #3b82f6; #8b5cf6; #ec4899" dur="10s" repeatCount="indefinite" />
                </stop>
            </linearGradient>
        </defs>
    </svg>
);

// Hero Component
const Hero = () => {
    const openResume = () => {
        // Open the resume PDF in a new tab
        window.open('/resume/Resume_JJohnson_SEP_2024.pdf', '_blank');
    };

    return (
        <div className="bg-gradient-to-b from-black to-gray-900 h-screen flex items-center">
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                <motion.div
                    initial={{ opacity: 0, y: -50 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.5 }}
                >
                    <h1 className="text-4xl font-bold text-white sm:text-5xl md:text-6xl">
                        Hello, I'm <span className="text-indigo-400">Jalen Johnson</span>
                    </h1>
                    <p className="mt-3 text-base text-gray-300 sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl lg:mx-0">
                        Welcome to my Portfolio. I'm a Freshman Physics Major Developing Skills in Embedded Systems Engineering.
                    </p>
                    <div className="mt-8">
                        <motion.button
                            onClick={openResume}
                            className="inline-block relative overflow-hidden rounded-full p-[3px]"
                            whileHover={{ scale: 1.05 }}
                            whileTap={{ scale: 0.95 }}
                        >
                            <MorphingShape />
                            <ParticleEffect />
                            <motion.div
                                className="absolute inset-0 opacity-50"
                                style={{
                                    backgroundImage: 'linear-gradient(45deg, transparent 25%, rgba(255,255,255,0.2) 25%, rgba(255,255,255,0.2) 50%, transparent 50%, transparent 75%, rgba(255,255,255,0.2) 75%, rgba(255,255,255,0.2) 100%)',
                                    backgroundSize: '30px 30px',
                                }}
                                animate={{
                                    x: [0, 30],
                                    y: [0, 30],
                                }}
                                transition={{
                                    duration: 1,
                                    repeat: Infinity,
                                    ease: "linear"
                                }}
                            />
                            <motion.div
                                className="relative block px-6 py-3 bg-black bg-opacity-50 backdrop-filter backdrop-blur-sm text-white font-semibold rounded-full"
                                animate={{
                                    boxShadow: [
                                        '0 0 0 0 rgba(255,255,255,0.7)',
                                        '0 0 0 10px rgba(255,255,255,0)',
                                    ]
                                }}
                                transition={{
                                    duration: 1.5,
                                    repeat: Infinity,
                                    ease: "easeInOut"
                                }}
                            >
                                {['Open', ' ', 'Resume'].map((word, wordIndex) => (
                                    <React.Fragment key={wordIndex}>
                                        {word.split('').map((char, charIndex) => (
                                            <motion.span
                                                key={charIndex}
                                                initial={{ opacity: 0 }}
                                                animate={{ opacity: 1 }}
                                                transition={{ duration: 0.5, delay: 0.2 + (wordIndex * word.length + charIndex) * 0.1 }}
                                            >
                                                {char}
                                            </motion.span>
                                        ))}
                                        {wordIndex < 2 && ' '}
                                    </React.Fragment>
                                ))}
                            </motion.div>
                        </motion.button>
                    </div>
                </motion.div>
            </div>
        </div>
    );
};

export default Hero;